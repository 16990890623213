<template>
    <div class="bgc-white p-20 bd" id="mainContent">
      <h4 class="c-grey-900">{{ $t("addClient") }}</h4>
      <div class="mT-30">
        <form @submit="checkForm">
          <div class="form-row mT-20">
            <div class="form-group col-md-12">
              <label for="inputEmail4">{{ $t("clientName") }}</label
              ><input type="name" class="form-control" id="inputEmail4" v-model="name" required />
            </div>
          </div>
          <div class="form-row mT-20">
            <div class="form-group col-md-12">
              <label for="inputPassword4">{{ $t("mobile_number") }}</label
              ><input dir="ltr" :title="$t('onlyNumber')" pattern="\d{8}" maxlength="8" type="tel" @keydown="validateKey" class="form-control" id="inputPassword4" v-model="mobile_number" required/>
            </div>
          </div>
          <div class="form-row mT-20">
            <loading v-show="loading" />
          <sui-button
            type="submit"
            positive
            class="btn btn-primary mL-10"
            :content="$t('add')"
            icon="check"
            label-position="left"
            :disabled="loading"
            v-show="!loading"
          />
          <sui-button
            type="button"
            :content="$t('cancel')"
            :disabled="loading"
            v-show="!loading"
            @click="$emit('closePanel', {})"
          />
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      reload: { type: Function },
    },
    data() {
      return {
        name: "",
        mobile_number: "",
        loading: false
      }
    },
    methods: {
      validateKey(event) {
      // Prevent typing if the key is not a number
      const isNumberOrAllowedKey = /^\d*$/.test(event.key) || [8, 37, 39, 46, 36, 35, 17, 91, 18, 67, 88, 86, 65, 13].includes(event.keyCode);    
      if (!isNumberOrAllowedKey) {
        event.preventDefault();
      }
    },
      getTranslation(ar , en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      checkForm: function (e) {
        e.preventDefault();
        this.loading = true;
        this.addSupervisor();
      },
      addSupervisor: function () {
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http
          .post(
            "/backend/clients/add",
            {
              name : this.name,
              mobile_number: this.mobile_number,
              client_type_id  :this.role,
              branch_id: this.branch_id
            },
            { headers }
          )
  
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 201:
                  this.$emit("closePanel", {});
                  this.success(this.$t("success"));
                  this.reload();
                  break;
              } 
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
              }
            }
          );
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function () {
        this.$toast.success(this.$t("success"), {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  #mainContent {
    text-align: right;
    direction: rtl;
  }
  
  </style>